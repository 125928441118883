import React from 'react';
import './footer.styles.css';

import logo from '../../assets/pandora_green.svg';
import footerBg from '../../assets/footer-bg.png';

const Footer = () => {
  return (
    <div
      className='footer-wrapper'
      style={{ backgroundImage: `url(${footerBg})` }}
    >
      <footer className='footer'>
        <div className='footer__logo'>
          <img src={logo} alt='footer-logo' />
          <p>Quitters never win winners never quit</p>
        </div>

        <div className='footer__info'>
          <div>
            <h2>Address</h2>
            <a href='#1'>Belize, Belize City, 9 Barrack Road</a>
          </div>
          <div>
            <h2>Our Information</h2>
            <a href='#1'>2019 Pandora Games © Akkido Limited</a>
          </div>
          <div>
            <h2>Contact</h2>
            <a href='#1'>contact@pandoragames.io</a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
