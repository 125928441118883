import React from 'react';
import './video.styles.css';

const Video = () => {
  return (
    <div className='video'>
      <div className='video-wrapper'>
        <video
          className='header-video'
          src='https://res.cloudinary.com/dez0s9s32/video/upload/v1569312998/pandora/Animation_1.mov'
          autoPlay
          preload='true'
          loop
          muted
          playsInline
        />
      </div>
      <section className='video-content'>
        <h1 data-text='The top provably fair gambling games provider'>
          The top provably fair gambling games provider
        </h1>
        <h3>Quitters never win winners never quit</h3>
      </section>
    </div>
  );
};

export default Video;
