import React from 'react';
import './additional.styles.css';

import AdditionalGallery from '../../components/additional-gallery/additional-gallery.component';
import AdditionalInfo from '../../components/additional-info/additional-info.component';

const AdditionalContainer = () => {
  return (
    <div className='additional'>
      <section className='additional__gallery'>
        <AdditionalGallery />
      </section>

      <section className='additional__info'>
        <AdditionalInfo />
      </section>
    </div>
  );
};

export default AdditionalContainer;
