import React from 'react';
import './additional-gallery.styles.css';
import Fade from 'react-reveal/Fade';

import roulette from '../../assets/additional-gallery/roulette.jpg';
import roulette2 from '../../assets/additional-gallery/roulette2.jpg';
import sicbo from '../../assets/additional-gallery/sicbo.jpg';
import dice from '../../assets/additional-gallery/dice.jpg';
import g777 from '../../assets/additional-gallery/g777.jpg';
import dragon from '../../assets/additional-gallery/dragon.jpg';

const AdditionalGallery = () => {
  return (
    <div className='gallery'>
      <div className='gallery__first'>
        <div className='left-first-img'>
          <Fade top delay={30}>
            <img src={roulette} alt='gallery-img' />
          </Fade>
        </div>

        <div className='left-img'>
          <Fade left delay={60}>
            <img src={roulette2} alt='gallery-img' />
          </Fade>
        </div>

        <div className='left-img'>
          <Fade left delay={60}>
            <img src={sicbo} alt='gallery-img' />
          </Fade>
        </div>

        <div className='left-img'>
          <Fade left delay={60}>
            <img src={dice} alt='gallery-img' />
          </Fade>
        </div>
      </div>

      <div className='gallery__second'>
        <Fade bottom delay={90}>
          <img src={g777} alt='gallery-img' />
        </Fade>
        <Fade bottom delay={90}>
          <img src={dragon} alt='gallery-img' />
        </Fade>
      </div>
    </div>
  );
};

export default AdditionalGallery;
