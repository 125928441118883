import React from 'react';
import './side-drawer.styles.css';
import { Link } from 'react-scroll';
import { withRouter } from 'react-router';

const SideDrawer = ({ show, toggleDrawer, history }) => {
  const handleSetActive = () => {
    toggleDrawer();
    history.replace('', history.location.pathname);
  };

  return (
    <div className={show ? 'side-drawer show' : 'side-drawer'}>
      <div className='navlinks'>
        <Link
          className='navlink'
          activeClass='active'
          to='home'
          spy={true}
          smooth={true}
          hashSpy={true}
          duration={500}
          isDynamic={true}
          onSetActive={handleSetActive}
        >
          Home
        </Link>
        <Link
          className='navlink'
          activeClass='active'
          to='games'
          spy={true}
          smooth={true}
          hashSpy={true}
          duration={500}
          isDynamic={true}
          onSetActive={handleSetActive}
        >
          Games
        </Link>
        <Link
          className='navlink'
          activeClass='active'
          to='features'
          spy={true}
          smooth={true}
          hashSpy={true}
          duration={500}
          isDynamic={true}
          onSetActive={handleSetActive}
        >
          Features
        </Link>
        <Link
          className='navlink'
          activeClass='active'
          to='about'
          spy={true}
          smooth={true}
          hashSpy={true}
          duration={500}
          isDynamic={true}
          onSetActive={handleSetActive}
        >
          About Us
        </Link>
      </div>

      <div className='nav-footer'>
        <p>Copyrights 2019 @ Pandora Games</p>
      </div>
    </div>
  );
};

export default withRouter(SideDrawer);
