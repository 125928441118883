import React, { useEffect } from 'react';

import { Element } from 'react-scroll';

import Header from '../components/header/header.component';
import GamesContainer from '../containers/games/games.container';
import ServicesContainer from '../containers/services/services.container';
import AdditionalContainer from '../containers/additional/additiona.container';

const HomePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Element name='home'>
        <Header />
      </Element>
      <Element name='games'>
        <GamesContainer />
      </Element>
      <Element name='features'>
        <ServicesContainer />
      </Element>
      <Element name='about'>
        <AdditionalContainer />
      </Element>
    </div>
  );
};

export default HomePage;
