import React from 'react';
import './App.css';
import { Route } from 'react-router-dom';

import HomePage from './pages/home.page';
import GamePage from './pages/games/game.page';
import Footer from './components/footer/footer.component';

function App() {
  return (
    <div className='App'>
      <Route path='/games/:game' exact component={GamePage} />
      <Route path='/' exact component={HomePage} />
      <Footer />
    </div>
  );
}

export default App;
