import React, { useState, useEffect } from 'react';
import './header.styles.css';

import Video from '../video/video.component';
import NavBar from '../navbar/navbar.component';

const Header = () => {
  const [yPos, setYPos] = useState(0);

  const scrollFunc = () => {
    const y = window.scrollY;
    setYPos(y);
  };

  useEffect(() => {
    window.addEventListener('scroll', scrollFunc);

    return () => window.removeEventListener('scroll', scrollFunc);
  });
  return (
    <div>
      <NavBar yPos={yPos} />
      <Video />
    </div>
  );
};

export default Header;
