import React from 'react';
import './game-card.styles.css';
import { withRouter } from 'react-router';

import certificate from '../../assets/game-card-imgs/certificate-img.png';

const GameCard = ({
  title,
  desc,
  img,
  game,
  history,
  handleClick,
  certificateUrl,
}) => {
  return (
    <div className='game-card'>
      <div className='game-card__img'>
        <img
          src={img}
          alt='game-card-img'
          onClick={() => history.push(`/games/${game}`)}
        />
      </div>

      <div className='game-content'>
        <h1 onClick={() => history.push(`/games/${game}`)}>{title}</h1>
        <article className='game-description'>{desc}</article>

        <div className='game-card__certificate'>
          <img src={certificate} alt='certificate-img' />
          <a href={certificateUrl} target='_blank' rel='noopener noreferrer'>
            Provably Fair
          </a>
        </div>
      </div>

      <div className='game-card__buttons'>
        <span
          onClick={() => history.push(`/games/${game}`)}
          className='more-btn'
        >
          MORE
        </span>

        <span onClick={handleClick} className='play-btn'>
          PLAY
        </span>
      </div>
    </div>
  );
};

export default withRouter(GameCard);
