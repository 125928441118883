import React from 'react';

const text = {
  g777: {
    desc:
      'Slot game 777 has 3 of spinning reels and 12 various symbols. The outcome of the game depands on the combinations the player gets from the game, the basic win starts when the outcome contains at least two similar simbols;',
    manual: (
      <span>
        The outcome of 777 depends on combination of symbols each of them have
        different payout, below are given the combinations and relevant
        outcomes:
        <br />
        <br /> - Two Similar Symbols In a Raw, multiplies by <b>2x</b> on
        average;
        <br />
        <br /> - Three Similar Symbols In a Raw, multiplies by <b>26x</b> on
        average;
        <br />
        <br /> - One Bar, Two Bars, Tree Bars, From Left to the Right,
        multiplies by <b>84x</b> on average;
        <br />
        <br /> - Tree Same Number Bars Same in a Raw
        (bar1-bar1-bar1|bar2-bar2-bar2|bar3-bar3-bar3), multiplies by <b>
          42x
        </b>{' '}
        on average;
        <br />
        <br /> - Tree Bars, Two Bars, one Bar From Right to Left, multiplies by{' '}
        <b>8x</b> on average;
        <br />
        <br />- The combination of 7 7 7 multiplies by <b>251x</b> on average;
        <br />
        <br />
        <h5>
          <b>
            The following game is 100% provably fair and the fairness of all
            outcomes can be verified by any interested party
          </b>
        </h5>
      </span>
    ),
  },
  dice: {
    desc: `The Dice is popular and attractive game, and it's due to it's simplicity and low house edge. The dice is very attractive and interesting, as one has more than 9999 possible outcomes (Roll numbers go from 0 to 99.99), and you can choose your own odds! - The game starts with defining the initial bet amount and choice of payout which starts from 1.0102x and goes beyond 9000x (depending on the house edge) or the chance of your bet from 0.01% up to 98% and click on 'Roll'. - The number under bet amount shows the Profit you will earn in case of win with the selected bet setting. You are free to choose if you want to Roll Over or Under a targeted number in order to win!`,
    manual: (
      <span>
        <br />- Choose the Bet Amount, by clicking the bet section, the popup
        menu will help you to define min bet, either divide by half or double
        the current bet, or set the maximum possible balance;
        <br />
        <br />- Choose the threshold by clicking 'Roll Under/Over' section, by
        defining the threshold number, which varies between 0.01 and 99.98, with
        respective probabilities and payouts, higher the win probability lower
        the payout and vice versa;
        <br />
        <br />- Alternatively the user can choose 'PAYOUT' section, by defining
        the payout, which varies between 1.001 and 9700, with respective
        probabilities and threshold number, higher the payout lower the win
        probability and vice versa;
        <br />
        <br />- The user can also choose 'CHANCE' section, by defining the
        chance, which varies between 0.01% and 98%, with respective payouts and
        threshold number, higher the win probability lower the payout and vice
        versa;
        <br />
        <br />- The user can also choose 'CHANCE' section, by defining the
        chance, which varies between 0.01% and 98%, with respective payouts and
        threshold number, higher the win probability lower the payout and vice
        versa;
        <br />
        <br />- The user also has the possibility to define the threshold by
        dragging the slider right or left, if the slider is dragged to the
        winning zone (green: winning zone, red: loosing zone), the payout will
        rise and chances decrease;
        <br />
        <br />- The user can swap the zones by clicking the arrow button placed
        on the left of the threshold slider;
        <br />
        <br />- After all parameters have been defined the user can click the
        ROLL button and enjoy the result;
        <br />
        <br />
        <h5>
          <b>
            The following game is 100% provably fair and the fairness of all
            outcomes can be verified by any interested party
          </b>
        </h5>
      </span>
    ),
  },
  dragonsBless: {
    desc: `The idea of the game is to break a winning tile, to move to the next step and pick another one, at any point the player can finish the play by clicking the END button and take out the collected ammount;
      `,
    manual: (
      <span>
        <br />
        <br /> - <b>UNBORN DRAGON MODE - EASY </b> (4 tiles: 3 winning tiles and
        1 losing tile);
        <br />
        <br /> - <b>JUST BORN DRAGON MODE - MEDIUM </b> (3 tiles: 2 winning
        tiles, 1 losing tile);
        <br />
        <br /> - <b>BABY DRAGON MODE - HARD </b> (2 tiles: 1 winning tile, 1
        losing tile);
        <br />
        <br /> - <b>TWO HEADED DRAGON MODE - EXTREME </b> (3 tiles: 1 winning
        tile, 2 losing tiles);
        <br />
        <br /> - <b>TRHEE HEADED DRAGON MODE - NIGHTMARE </b> (4 tiles: 1
        winning tile, 3 losing tiles);
        <br />
        <br />
        <h5>
          <b>
            The following game is 100% provably fair and the fairness of all
            outcomes can be verified by any interested party
          </b>
        </h5>
      </span>
    ),
  },
  plinko: {
    desc: `Plinko is a game played on a vertical board populated with offset rows of pegs, which together make the shape of triangle. The chip is dropped from the top into the slot, and the chip bounces down the board, and finally is placed in any of the basket, the odds are higher on outer basket compared to central ones;`,
    manual: (
      <span>
        <br />
        - Initially you choose game mode, with combination of risk and plinko
        obstacle levels, which define actual outcomes of the game;
        <br />
        <br />
        - By clicking the PLAY button the ball starts falling, the result
        depends on the obstacles which change the direction of the balls and
        define the final destination;
        <br />
        <br /> - Finally balls fall in one of the baskets , each basket has
        assigned odd , if the odd is less than 1 the game outcome is negative if
        the odd is more than 1 the game outcome is positive;
        <br />
        <br />
        - During the high risk mode side cells’ odds are increased, but middle
        cell odds are reduced.
        <br />
        <br /> - You can also adjust the number of cells: The less number of
        cells means a small number of obstacles, therefore the odds of the cells
        are reduced.
        <br />
        <br />
        <h5>
          <b>
            The following game is 100% provably fair and the fairness of all
            outcomes can be verified by any interested party{' '}
          </b>
        </h5>
      </span>
    ),
  },
  roulette: {
    desc: `Roulette is a casino game named after the French word meaning little wheel. In each play of roulette, a croupier spins a roulette wheel in one direction, then rolls a ball in the opposite direction in a tilted circular track that surrounds the wheel. The ball gradually loses momentum, passes through an area of deflectors, and eventually falls into one of several colored and numbered pockets along the edge of the wheel. Players can bet on whether the ball will fall into the pockets numbered 1–36, but not on a pocket that belongs to the house (that is, the casino). In French (or European) style roulette, the pocket numbered 0 belongs to the house; in American style roulette, the pockets numbered 0 and 00 belong to the house. The croupier pays the winnings to any player that placed a successful bet. Casino offers the players several ways to bet. They can bet on individual numbers, various groupings of numbers, the colors red or black, whether the number is odd or even, or whether the numbers are high (19–36) or low (1–18). If the ball falls into a pocket that belongs to the house, all the players lose and the house collects all the bets.`,
    manual: ``,
  },
  sicbo: {
    desc: `Sic bo , also known as tai sai , dai siu , big and small or hi-lo, is an unequal game of chance of ancient Chinese origin played with three dice. Grand hazard and chuck-a-luck are variants, both of English origin. The literal meaning of sic bo is "precious dice", while dai siu and dai sai mean "big [or] small". Sic bo is a casino game, mostly popular in Asia and widely played (as dai siu) in casinos in Macau. How ever the game is gaining popularity in wester part as well; Gameplay involves betting a certain condition and the result depands on matching that certain condition.`,
    manual: `The Sic Bo table features a variety of dice combinations that players can bet on. Every possible bet is illustrated along with their associated payout odds, making it easy for new players to quickly pick up the game. The game is played with three dice and players bet on the outcome of each roll. The Sic Bo dice are “rolled” using a provably fair dice randomizer. This electronic board displays the outcomes of recent rounds of play. Take this information with a grain of salt. Every roll of the dice is purely random and independent of the last.`,
  },
};

export default text;
