import React from 'react';
import './additional-info.styles.css';

const AdditionalInfo = () => {
  return (
    <div className='info'>
      <h2>Why are pandora games so special ?!</h2>
      <p>Pandora is the top provably fair game API provider</p>

      <div className='info-highlights'>
        <div className='highlight'>
          <span className='fas fa-arrow-right' aria-hidden></span>
          <h2>100% provably fairness guaranteed for each game</h2>
        </div>
        <div className='highlight'>
          <span className='fas fa-arrow-right' aria-hidden></span>
          <h2>The mixture of traditional and creative games</h2>
        </div>
        <div className='highlight'>
          <span className='fas fa-arrow-right' aria-hidden></span>
          <h2>Mobile and desktop versions unified in each game</h2>
        </div>
        <div className='highlight'>
          <span className='fas fa-arrow-right' aria-hidden></span>
          <h2>24/7 Hour onine supports.</h2>
        </div>
      </div>
    </div>
  );
};

export default AdditionalInfo;
