import React, { useState } from 'react';
import './navbar.styles.css';
import { withRouter } from 'react-router';

import Logo from '../../assets/pandora_green.svg';

import SideDrawer from '../side-drawer/side-drawer.component';
import Backdrop from '../backdrop/backdrop.component';

const NavBar = ({ yPos, history, match }) => {
  const [showDrawer, setShowDrawer] = useState(false);

  const toggleDrawer = () => setShowDrawer(!showDrawer);
  const setDrawerFalse = () => setShowDrawer(false);

  return (
    <div className={yPos >= 1 ? 'navbar nav-white' : 'navbar'}>
      <div
        className='navbar__logo'
        onClick={() => {
          if (match.url === '/') {
            window.scrollTo(0, 0);
          } else {
            history.push('/');
          }
        }}
      >
        <img src={Logo} alt='Logo' />
      </div>

      <div className='navbar__burger' onClick={toggleDrawer}>
        <span
          className={yPos >= 1 ? 'burger-span green' : 'burger-span'}
        ></span>
        <span
          className={yPos >= 1 ? 'burger-span green' : 'burger-span'}
        ></span>
        <span
          className={yPos >= 1 ? 'burger-span green last' : 'burger-span last'}
        ></span>
      </div>

      <SideDrawer show={showDrawer} toggleDrawer={setDrawerFalse} />
      {showDrawer && <Backdrop click={toggleDrawer} />}
    </div>
  );
};

export default withRouter(NavBar);
