import React from 'react';
import './services.styles.css';

import ServiceCard from '../../components/service-card/service-card.component';

const ServicesContainer = () => {
  return (
    <div className='services'>
      <div className='services__title'>
        <span>OUR SERVICES</span>
        <h2>Featured Service that We Provide</h2>
      </div>

      <div className='service-cards'>
        <ServiceCard
          icon='fas fa-cogs'
          title='Games API'
          desc='Easy simple and unified API for all games'
          bgColor='-60deg,rgba(241,39,17,0.7),rgba(245,175,25,0.7)'
        />
        <ServiceCard
          icon='fas fa-dice'
          title='Traditional and Creative Gambling Games'
          desc='Our team offers various style games both for desktop and mobile platforms, each game is 
          designed efficiently for effective usage of the device resources'
          bgColor='-60deg,rgba(50,207,167,0.75),rgba(150,201,61,0.75)'
        />
        <ServiceCard
          icon='fas fa-balance-scale'
          title='Provably Fair'
          desc='The concept of Pandora games assumes providing only provably fairs games, all games provided by Pandora has bet fairness check function and 
          any interested user can verify the fairness of the outcome.'
          bgColor='-60deg,rgba(236,0,140,0.75),rgba(255,103,103,0.75)'
        />
        <ServiceCard
          icon='fas fa-rocket'
          title='Leading Performance'
          desc='We are committed to provide gambling APIs with leading technologies, giving the customers the unique experience,
           in terms of efficiency and quality of the service'
          bgColor='-60deg,rgba(47,128,237,0.75),rgba(86,204,242,0.75)'
        />
        <ServiceCard
          icon='fa fa-chart-bar'
          title='Performance Analysis'
          desc='Each our customer has access to web analytics tool 
          which provides general and individual statistical data'
          bgColor='-60deg,rgba(110,72,170,0.75),rgba(192,91,210,0.75)'
        />
        <ServiceCard
          icon='fas fa-cogs'
          title='24 Hour Support'
          desc='Our team is open to conduct 24 hour
           assistance via online chat or voice support tools'
          bgColor='-60deg,rgba(0,57,115,0.75),rgba(299,299,199,0.75)'
        />
      </div>
    </div>
  );
};

export default ServicesContainer;
