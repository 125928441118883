import React, { useState, useEffect } from 'react';
import axios from 'axios';
import qs from 'querystring';
import './games.container.css';

import gameCard777 from '../../assets/game-card-imgs/777-game-card.png';
import gameCardDice from '../../assets/game-card-imgs/dice-game-card.png';
import gameCardDragon from '../../assets/game-card-imgs/dragons_bless_game-card.png';
import gameCardPlinko from '../../assets/game-card-imgs/plinko_game-card.png';
import gameCardRoulette from '../../assets/game-card-imgs/roulette_game-card.png';
import gameCardScibo from '../../assets/game-card-imgs/sicbo_game-card.png';

import GameCard from '../../components/game-card/game-card.component';

import { IoMdClose } from 'react-icons/io';

const GamesContainer = () => {
  const [gameUrl, setGameUrl] = useState(null);

  useEffect(() => {
    window.addEventListener(
      'message',
      (event) => {
        if (!event || !event.data) return;
        const data = event.data;
        if (typeof data === 'object' && data !== null) {
          if (data.sender === 'pandora') {
            if (data.action === 'go_home') {
              setGameUrl(null);
            } else if (data.action === 'bet_share') {
              //create some button with provided data. On click navigate to the provided URL.
            }
          }
        }
      },
      false
    );
  }, []);

  const fetchGameUrl = async (name) => {
    const res = await axios.post(
      'https://demo.pandoragames.io/gameStart',
      qs.stringify({ game_uuid: name }),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );

    setGameUrl(res.data.url);
  };

  return (
    <div className='games'>
      <div className='games__title'>
        <span>OUR GAMES</span>
        <h2>AVTIVE PANDORA GAMES</h2>
      </div>

      <div className='games__cards'>
        <GameCard
          handleClick={() => fetchGameUrl('g777')}
          game='g777'
          title='777'
          desc='Slot game 777 has 3 of spinning reels and 12 various symbols. The
        outcome of the game depands on the combinations the player gets from
        the ...'
          img={gameCard777}
          certificateUrl='https://codepen.io/pandoragames/pen/PoooBjO'
        />
        <GameCard
          handleClick={() => fetchGameUrl('dice')}
          game='dice'
          title='Dice'
          desc="The Dice is popular and attractive game,
           and it's due to it's simplicity and low house edge.
            The dice is very attractive and interesting, as..."
          img={gameCardDice}
          certificateUrl='https://codepen.io/pandoragames/pen/GRRRGbN'
        />
        <GameCard
          handleClick={() => fetchGameUrl('dragonsBless')}
          game='dragonsBless'
          title='Dragons Bless'
          desc='The idea of the game is to break a winning tile,
           to move to the next step and pick another one, 
           at any point the player can finish the play ...'
          img={gameCardDragon}
          certificateUrl='https://codepen.io/pandoragames/pen/LYYPWOJ'
        />

        <GameCard
          handleClick={() => fetchGameUrl('plinko')}
          game='plinko'
          title='Plinko'
          desc='Plinko is a game played on a vertical
         board populated with offset rows of pegs, which together 
         make the shape of triangle. The chip is drop...'
          img={gameCardPlinko}
          certificateUrl='https://codepen.io/pandoragames/pen/dyybxpe'
        />
        <GameCard
          handleClick={() => fetchGameUrl('roulette')}
          game='roulette'
          title='Roulette'
          desc='Roulette is a casino game named after 
        the French word meaning little wheel.
         In each play of roulette, a croupier spins a roulette wheel in o...'
          img={gameCardRoulette}
          certificateUrl='https://codepen.io/pandoragames/pen/BaaaVJL'
        />
        <GameCard
          handleClick={() => fetchGameUrl('sicbo')}
          game='sicbo'
          title='Sicbo'
          desc='Sic bo , also known as tai sai , 
        dai siu , big and small or hi-lo, 
        is an unequal game of chance of ancient Chinese origin played with three ...'
          img={gameCardScibo}
          certificateUrl='https://codepen.io/pandoragames/pen/Poooaxb'
        />
      </div>

      {gameUrl && (
        <div className='iframe-wrapper'>
          <span onClick={() => setGameUrl(null)}>
            Close <IoMdClose />
          </span>
          <iframe
            title='unique '
            src={gameUrl}
            frameBorder='10px'
            width='100%'
            height='100%'
          ></iframe>
        </div>
      )}
    </div>
  );
};

export default GamesContainer;
