import React from 'react';
import './service-card.styles.css';

const ServiceCard = ({ icon, title, desc, bgColor }) => {
  return (
    <div className='service-card'>
      <div
        className='service-card__icon'
        style={{
          backgroundImage: `linear-gradient( ${bgColor} )`,
        }}
      >
        <i aria-hidden className={icon}></i>
      </div>
      <div className='service-card__title'>
        <h2>{title}</h2>
      </div>
      <div className='service-card__desc'>
        <p>{desc}</p>
      </div>
    </div>
  );
};

export default ServiceCard;
