import React, { useEffect, useState } from 'react';
import axios from 'axios';
import qs from 'querystring';
import './game-page.styles.css';

import text from '../../assets/game-text';

import g777 from '../../assets/game-card-imgs/777-game-card.png';
import dice from '../../assets/game-card-imgs/dice-game-card.png';
import dragonsBless from '../../assets/game-card-imgs/dragons_bless_game-card.png';
import plinko from '../../assets/game-card-imgs/plinko_game-card.png';
import roulette from '../../assets/game-card-imgs/roulette_game-card.png';
import sicbo from '../../assets/game-card-imgs/sicbo_game-card.png';

import Navbar from '../../components/navbar/navbar.component';

import { IoMdClose } from 'react-icons/io';

const imgs = { g777, dice, dragonsBless, plinko, roulette, sicbo };

const GamePage = (props) => {
  const game = props.match.params.game;

  const [gameUrl, setGameUrl] = useState(null);

  const fetchGameUrl = async (name) => {
    const res = await axios.post(
      'https://demo.pandoragames.io/gameStart',
      qs.stringify({ game_uuid: name }),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );

    setGameUrl(res.data.url);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Navbar yPos='2' />
      <div className='game-page'>
        <header>
          <h1 className='game-page__title'>
            {game === 'g777' ? game.replace('g', '') : game}
          </h1>
        </header>
        <div className='game-page__content'>
          <div className='game-page__img'>
            <div className='game-page__image'>
              <img src={imgs[game]} alt='GameImage' />
            </div>
            <span onClick={() => fetchGameUrl(game)}>
              Play {game === 'g777' ? game.replace('g', '') : game}
            </span>
          </div>

          <div className='game-page__details'>
            <article className='details-description'>
              <h2>Description</h2>
              <p>{text[game].desc}</p>
            </article>

            <article className='details-manual'>
              <h2>
                How To play{' '}
                <span>{game === 'g777' ? game.replace('g', '') : game}</span>
              </h2>
              <div>{text[game].manual}</div>
            </article>
          </div>
        </div>
      </div>
      {gameUrl && (
        <div className='iframe-wrapper'>
          <span onClick={() => setGameUrl(null)}>
            Close <IoMdClose />
          </span>
          <iframe
            title='unique '
            src={gameUrl}
            frameBorder='10px'
            width='100%'
            height='100%'
          ></iframe>
        </div>
      )}
    </div>
  );
};

export default GamePage;
